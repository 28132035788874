import React, { useEffect, useState } from "react"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useRoutes } from "../../hooks/useRoutes"
import { animateScrollTo, position } from "../../utils/animateScrollTo"
import { throttle } from "lodash-es"

export const withCatalogue = Component => ({
  name = "Catalogue",
  page,
  location,
}) => {
  const {
    helpers: { storage, isDomReady },
  } = useCore()
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const { getUrlParameter, setUrlParameter } = useRoutes()
  const currentActiveTab = getUrlParameter("active", location)
  const { title, tabs, tabsAlign } = page || {}

  const [activeTab, setActiveTab] = useState(currentActiveTab || "")
  const [showBackToTop, setShowBackToTop] = useState(false)

  const handleClick = tabTitle => {
    setActiveTab(tabTitle)
    window.history.replaceState(null, "", setUrlParameter("active", tabTitle))
  }

  const tabTitles = tabs.map(tab => tab.title)
  const defaultActiveTabIndex =
    tabTitles.indexOf(activeTab) === -1 ? 0 : tabTitles.indexOf(activeTab)

  useEffect(() => {
    const lastScrollString = storage.get(keys?.catalogue_scroll_position)
    try {
      const lastScroll = JSON.parse(lastScrollString)
      const currentTime = new Date().getTime()

      if (
        lastScroll?.activeTab === activeTab &&
        currentTime - lastScroll.clickedTime < 30000
      ) {
        animateScrollTo(lastScroll?.position)
      }
    } catch (error) {
      throw new Error("last scroll position object parse error")
    }
    // intentionally only execute this hook on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const storeCurrentPosition = () => {
      const currentPosition = position()
      const clickedTime = new Date().getTime()
      storage.set(
        keys?.catalogue_scroll_position,
        JSON.stringify({
          activeTab,
          position: currentPosition,
          clickedTime,
        }),
      )
    }
    const throttledStoreCurrentPosition = throttle(storeCurrentPosition, 200)
    window.addEventListener("scroll", throttledStoreCurrentPosition)
    return () =>
      window.removeEventListener("scroll", throttledStoreCurrentPosition)
  }, [activeTab, keys?.catalogue_scroll_position, storage])

  useEffect(() => {
    setTimeout(() => {
      setShowBackToTop(true)
    }, 1000)
  }, [])

  Component.displayName = name
  return isDomReady ? (
    <Component
      title={title}
      tabs={tabs}
      tabsAlign={tabsAlign}
      handleClick={handleClick}
      activeTab={activeTab}
      defaultActiveTabIndex={defaultActiveTabIndex}
      showBackToTop={showBackToTop}
    />
  ) : null
}
