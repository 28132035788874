import tw, { styled } from "twin.macro"
import { TabList } from "@reach/tabs"
import { Icon } from "../Icon/Icon"

export const Wrapper = tw.div`
  pt-3 md:pt-4 pb-8 md:pb-12
`

export const Title = tw.h1`
  pt-8 text-35 leading-1.28 tracking-3 md:text-60 md:leading-none tracking-2 font-bold uppercase mb-8 text-center
`

export const StyledTabList = styled(TabList)`
  ${tw`mx-2 md:mx-auto max-w-lg flex flex-col md:flex-row md:justify-center`}
  ${({ tabsAlign }) => {
    switch (tabsAlign) {
      case "left":
        return tw`md:justify-start`
      case "right":
        return tw`md:justify-end`
      default:
        return tw`md:justify-center`
    }
  }}
`

export const TabTitle = styled.div`
  ${tw`p-0-8 md:p-1-6`}
`

export const BackToTop = tw.button`
  flex relative z-10 items-start my-2 p-2 mx-auto cursor-pointer text-14
`

export const StyledIcon = styled(Icon)`
  ${tw`transform rotate-180 mr-1 inline-block`}
`
