import React from "react"
import { withCatalogue } from "./withCatalogue"
import { Tabs, Tab, TabPanels, TabPanel } from "@reach/tabs"
import {
  Wrapper,
  StyledTabList,
  TabTitle,
  BackToTop,
  StyledIcon,
} from "./CatalogueStyles"
import { Sections } from "../Sections/Sections"
import { animateScrollTo } from "../../utils/animateScrollTo"

export const Catalogue = withCatalogue(
  ({ tabs, tabsAlign, handleClick, defaultActiveTabIndex, showBackToTop }) => (
    <Wrapper className="catalogue">
      <Tabs defaultIndex={defaultActiveTabIndex}>
        <StyledTabList tabsAlign={tabsAlign}>
          {tabs.map(tab => (
            <Tab key={tab._key}>
              <TabTitle onClick={() => handleClick(tab.title)}>
                {tab.title}
              </TabTitle>
            </Tab>
          ))}
        </StyledTabList>

        <TabPanels>
          {tabs.map(tab => (
            <TabPanel key={tab._key}>
              <Sections page={{ content: tab.sections }} />
              {tab?.showBackToTop && showBackToTop && (
                <BackToTop
                  onClick={() => animateScrollTo(0)}
                  title="Back to top"
                >
                  <StyledIcon name={"chevronDown"} width={14} height={14} />
                  <span>Back to top</span>
                </BackToTop>
              )}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Wrapper>
  ),
)
