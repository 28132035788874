import React from "react"
import { graphql } from "gatsby"

import { Catalogue as Template } from "../components/Catalogue/Catalogue"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query($id: String!) {
    page: sanityCatalogue(_id: { eq: $id }) {
      title
      slug {
        current
      }
      tabsAlign
      tabs {
        title
        sections: _rawSections(resolveReferences: { maxDepth: 10 })
        showBackToTop
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("catalogue_page")
  
  return <Template {...props} {...data} />
}
export default Component
